import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import { withStyles } from '@mui/styles';
import SlideshowIcon from '@mui/icons-material/Slideshow';

const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF"
    }
  })(Typography);

function DateField(props ) {
    let {obj} = props
    let {date, color} = obj;

    date = new Date(date)
    const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

    let short_month = date.toLocaleString(userLocale, { month: 'short' });
    return (
            <Grid     
            align="center"
            justifyContent="center"
            direction="column" 
            container 
            rowSpacing={0} 
            sx={{ 
                backgroundColor: color,
                display: 'flex', 
                flexDirection: 'column',
                width: "151px" }}>
                <Grid item>
                <WhiteTextTypography variant="h3" component="span">
                {date.getDate()}
                </WhiteTextTypography>
                </Grid>
                <Grid item>
                <WhiteTextTypography variant="h6" component="span">
                {short_month.toUpperCase()}
                </WhiteTextTypography>
                </Grid>
            </Grid>
    )
}



export default function MediaControlCard(props) {
  const theme = useTheme();
    const {obj} = props;
  return (
    <Card sx={{ display: 'flex' }}>
    <DateField  obj={obj}    />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto' }} style={{ paddingBottom: "0px", paddingTop: "0px" }}> 
            <Typography component="div" variant="h5">
                {obj.title}
            </Typography>
            <Grid container direction="row" alignItems="center">
                <Grid item>
                    <SlideshowIcon display={{ xs: "none", md: "block" }}/>
                </Grid>
                <Grid item>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {obj.times}
                    </Typography>
                </Grid>
            </Grid>
        </CardContent>
      </Box>

    </Card>
  );
}