import { Grid } from '@mui/material';
import './App.css';
import Card from './Card';
import { ReactComponent as TikkieIcon } from './assets/donate-tikkie.svg';

import TitleImage from './assets/RiethovenStraalt.png';
import data from './assets/dates.json';

let dates = data.data;

function App() {
  return (
    <div className="App">
      <header className="App-header">
      <Grid container rowSpacing={0} sx={{ width: 1}} style={{paddingTop:'1rem'}} >
          <Grid item xs={0} md={9} sx={
              { 
                mt: { xs: '10px', md: '3rem' },
                mb: { xs: '-6rem' },
              }
            } >
            <img src={TitleImage} alt="Riethoven Straalt" style={{ width: '80%'}}/>
          </Grid>
          <Grid container xs={12} md={3} rowSpacing={1} style={{paddingRight: '1rem', paddingLeft: '1rem'}}>
          <Grid  item xs={12} justifyContent="center">   
            <a href="https://tikkie.me/pay/4lrqp8kup773o8ppt6ur">
              <TikkieIcon style={{height: '3em', width: '200px'}}/>
            </a>
          </Grid>

          {dates.map((object, i) => {
              let now = +new Date();
              let date = new Date(object.date);
              date.setDate(date.getDate() + 1)
              if (now > date) return null
              else
              return <Grid key={i} item xs={12} justifyContent="center">
                <Card obj={object} />
              </Grid>
              }
              )}
          </Grid>
          </Grid>
       </header>
    </div>
  );
}

export default App;
